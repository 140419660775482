<template>
    <b-card title="Employee List">
        <b-form>
            <b-row>
                <b-col md="3">
                    <b-form-group label="Store Name" class="mt-1">
                        <vSelect :options="storeNameOptions" v-model="storeName" label="storeName"
                            placeholder="---Select--- ">
                        </vSelect>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="User Role" class="mt-1">
                        <vSelect :options="userRoleOptions" v-model="userRole" label="name" placeholder="---Select--- ">
                        </vSelect>
                    </b-form-group>
                </b-col>
                <b-col md="2" class="text-center">
                    <b-button block variant="primary" class="mt-3" @click="getEmployeeFilter">Filter</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form>
                        <b-input-group class="mt-2 mr-2">
                            <b-input-group-prepend>
                                <div>
                                    <vSelect :options="searchCritriaOption" label="name" v-model="searchCritria"
                                        placeholder="Search ">
                                    </vSelect>
                                </div>
                            </b-input-group-prepend>
                            <b-form-input v-model="mobileNo" placeholder="Search Employee"
                                @input="onInputChange"></b-form-input>
                        </b-input-group>
                    </b-form>
                </b-col>
            </b-row>
            <hr>
            <div>
                <!-- search input -->
                <div class="custom-search">
                    <b-row>
                        <b-col md="4">
                            <b-form-group>
                                <b-button @click="getAllEmployeList">Get All Employee List</b-button>
                            </b-form-group>
                        </b-col>
                        <b-col md="8">
                            <b-form-group>
                                <div class="d-flex align-items-center">
                                    <label class="mr-1">Search</label>
                                    <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                        class="d-inline-block" />
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>

                <!-- table -->
                <vue-good-table ref="table" :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction"
                    :search-options="{
                        enabled: true,
                        externalQuery: searchTerm
                    }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">

                        <!-- Column: Name -->
                        <span v-if="props.column.field === 'photo'" class="text-nowrap">
                            <img v-if="props.row.photo != ''" :src=convertImage(props.row.photo) height="70px" />
                            <b-row class="imageIcons">
                                <b-col md="6">
                                    <span class="text-nowrap">
                                        <feather-icon icon="DropletIcon" class="bloodDrop" /> <span
                                            class="bloodGroup">{{
                                                props.row.booldGroup
                                            }}</span>
                                    </span>
                                </b-col>
                                <b-col md="6">
                                    <span v-if="props.row.active === true" class="text-nowrap">
                                        <feather-icon icon="CheckIcon" class="isActive" />
                                    </span>
                                    <span v-if="props.row.active === false" class="text-nowrap">
                                        <feather-icon icon="CheckIcon" class="isInActive" />
                                    </span>
                                </b-col>
                            </b-row>

                        </span>

                        <!-- Column: Name -->
                        <span v-else-if="props.column.field === 'name'">

                            <span class="text-nowrap">
                                {{ props.row.name }}
                            </span><br>
                            <span class="text-nowrap empDetail">
                                [ {{ props.row.mobileNo }} ]
                            </span><br>
                            <span class="text-nowrap empDetail">
                                {{ props.row.barcodeId }}
                            </span>

                        </span>

                        <!-- Column: Roles -->

                        <span v-else-if="props.column.field === 'roles'">
                            <span v-for="item in  props.row.roles" :key="item" class="text-nowrap">
                                <li style="list-style: none;" class="empDetail">{{ item.name }}</li>
                            </span>
                        </span>

                        <!-- Column: Action -->
                        <span v-else-if="props.column.field === 'action'">
                            <span>
                                <b-button v-b-toggle.sidebar-right @click="editButton(props.row.empId)"
                                    v-b-tooltip.hover.bottom="'Edit Employee'" variant="flat-primary"><feather-icon
                                        icon="EditIcon" class="text-body align-middle mr-25" /></b-button><br>
                                <!-- <b-button @click="deleteEmployee(props.row.empId)"
                                    v-b-tooltip.hover.bottom="'Delete Employee'" variant="flat-primary"><feather-icon
                                        icon="TrashIcon" class="text-body align-middle mr-25" /></b-button><br> -->
                                <b-button v-b-tooltip.hover.bottom="'Change Password'"
                                    variant="flat-primary"><feather-icon icon="FileTextIcon"
                                        v-b-toggle.sidebar-right-changePassword
                                        class="text-body align-middle mr-25" /></b-button>
                            </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
                <b-sidebar id="sidebar-right" right shadow bg-variant="white" width="80%" backdrop>
                    <EditEmployee :employeeDetails="employeeDetails" :getEmployeeList="getEmployeeList" />
                </b-sidebar>

                <b-sidebar id="sidebar-right-changePassword" right shadow bg-variant="white" width="80%" backdrop>
                    <change-password />
                </b-sidebar>
            </div>
        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BCard, VBToggle, BSidebar, VBTooltip, BRow, BCol, BInputGroup, BInputGroupPrepend, BForm, BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import api from '@/store/api'
import EditEmployee from './EditEmployee.vue'
import util from '@/store/utils'
import ChangePassword from '../../admin/ChangePassword.vue'

export default {
    components: {
        VueGoodTable, vSelect, VBTooltip, VBToggle,
        BAvatar, BSidebar,
        BBadge,
        BPagination, BInputGroup, BInputGroupPrepend,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton, EditEmployee, ChangePassword
    },
    directives: {
        'b-toggle': VBToggle,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Photo',
                    field: 'photo',
                },
                {
                    label: 'Employee',
                    field: 'name',
                },
                {
                    label: 'Store Name',
                    field: 'storeName',
                },
                {
                    label: 'Emergency Contact No',
                    field: 'emergencyContactNo',
                },
                {
                    label: 'Join Date',
                    field: 'joinDate',
                },
                {
                    label: 'Job Type',
                    field: 'jobType',
                },
                {
                    label: 'User Roles',
                    field: 'roles',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            searchCritriaOption: [],
            searchCritria: { 'filterType': 'mobileNo', 'name': 'MOBILE_NO' },
            mobileNo: '',
            storeNameOptions: [],
            storeName: '',
            userRoleOptions: [],
            userRole: '',
            registrationSourceOption: [
                { 'id': 1, 'name': 'Direct' },
                { 'id': 2, 'name': 'Marketing' },
                { 'id': 3, 'name': 'Google' },
                { 'id': 4, 'name': 'Other' }
            ],
            registrationSource: null,

            employeeDetails: {},

        }
    },
    methods: {
        convertImage(photo) {
            if (photo == null) {
                return require('@/assets/images/avatars/placeholderImage.png');
            } else {
                return 'data:image/*;base64,' + photo;
            }

        },
        editButton(id) {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/getEmployee/' + id, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    if (response.data.user.customerPreference == null) {
                        self.customerPreference = null;
                    } else {
                        self.customerPreference = response.data.user.customerPreference;
                    }
                    if (response.data.user.communicationPrefrence == null) {
                        self.communicationPreference = null;
                    } else {
                        self.communicationPreference = response.data.user.communicationPrefrence;
                    }
                    if (response.data.user.enabelHomeDelevery == true) {
                        self.homeDelivery = 'Yes';
                    } else {
                        self.homeDelivery = 'No';
                    }
                    if (response.data.user.registrationSource > 0) {
                        const object = self.registrationSourceOption.find(obj => obj.id == response.data.user.registrationSource);
                        self.registrationSource = { 'id': object.id, 'name': object.name };
                    }
                    if (response.data.registrationSource == 0) {
                        self.registrationSource = 0;
                    }
                    let userRoleName = [];
                    for (let index = 0; index < response.data.user.roles.length; index++) {
                        userRoleName.push(response.data.user.roles[index].name)

                    }

                    let employeeNameId=0;
                    if (util.digits_count(id) == 3) {
                        employeeNameId = "0" + id;
                    } else if (util.digits_count(id) == 2) {
                        employeeNameId = "00" + id;
                    }
                    else if (util.digits_count(id) == 1) {
                        employeeNameId = "000" + id;
                    }
                    self.employeeDetails = {
                        'id': response.data.user.id,
                        'namePrefix': response.data.user.namePrefix,
                        'name': response.data.user.name,
                        'mobileNo': response.data.user.mobileNo,
                        'emailId': response.data.user.emailId,
                        'address': response.data.user.address,
                        'areaLocation': response.data.user.areaLocation,
                        'state': response.data.user.state,
                        'pinCode': response.data.user.pinCode,
                        'customerPreference': response.data.user.customerPreference,
                        'defaultDiscountPercentage': response.data.user.defaultDiscountPercentage,
                        'communicationPrefrence': response.data.user.communicationPrefrence,
                        'gstin': response.data.user.gstin,
                        'membershipId': response.data.user.membershipId,
                        'barcode': response.data.user.barcode,
                        'birthday': response.data.user.birthday,
                        'anniversary': response.data.user.anniversary,
                        'remarks': response.data.user.remarks,
                        'enabelHomeDelevery': self.homeDelivery,
                        'marketingSource': response.data.user.marketingSource,
                        'alternetPhone': response.data.user.alternetPhone,
                        'facebookId': response.data.user.facebookId,
                        'registrationSource': self.registrationSource,
                        'msgOnSearchCustomer': response.data.user.msgOnSearchCustomer,
                        'messageOnGarmentDelivery': response.data.user.msgOnGarmentDelevery,
                        'empId': response.data.id,
                        'employeeNameId':employeeNameId,
                        'storeName': { 'id': response.data.store.id, 'storeName': response.data.store.storeName },
                        'bankDetail': response.data.bankDetail,
                        'panNo': response.data.panDetail,
                        'adharDetail': response.data.adharDetail,
                        'emergencyContactNo': response.data.emergencyContactNo,
                        'barcodeId': response.data.barcodeId,
                        'salary': response.data.salary,
                        'qualification': response.data.qualification,
                        'joinDate': response.data.joinDate,
                        'jobType': response.data.jobType,
                        'department': response.data.department,
                        'employeeDetails': response.data.employeeDetails,
                        "experienceDetails": response.data.experienceDetails,
                        'leavingReason': response.data.leavingReason,
                        'leavingDate': response.data.leavingDate,
                        'bloodGroup': response.data.booldGroup,
                        'serviceType': response.data.serviceType,
                        'empRemarks': response.data.remarks,
                        'active': response.data.active,
                        'userRole': userRoleName
                    }



                    // self.mobileNo = '';

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            self.storeName = { 'id': self.$store.state.storeId, 'storeName': self.$store.state.storeName };
        },
        getUserRole() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/users/getAllRoles', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.userRoleOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onInputChange(text) {
            if (text === '' || text === undefined) {
                return
            }
            this.getEmployeeList(text);
        },
        getAllEmployeList(){
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/getStoreEmployeesDetails/' + self.$store.state.storeId + ',' + 0, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.rows = response.data;
                    } else {
                        self.rows = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getEmployeeList(text) {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/getEmployeesDetails/filter/' + self.searchCritria.filterType + ',' + text, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.rows = response.data;
                    } else {
                        self.rows = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getEmployeeFilter() {

            let self = this;
            if (!(self.storeName == '')) {
                if (self.userRole == '') {
                    self.userRole.id = 0;
                }
                var axios = require('axios');
                var data = '';
                axios(api.getApi('get', '/getStoreEmployeesDetails/' + self.storeName.id + ',' + self.userRole.id, data))
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        if (response.data != '') {
                            self.rows = response.data;
                        } else {
                            self.rows = []
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                self.$swal({
                    title: '',
                    text: "Select Store Name.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
        deleteEmployee(id) {

        },
        getSearchFiltersList() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getSearchFiltersList', data))
                .then(function (response) {
                    for (let index = 0; index < response.data.length; index++) {
                        if (response.data[index] == 'ALL') {
                            self.searchCritriaOption.push({ 'filterType': 'all', 'name': response.data[index] })
                        } else if (response.data[index] == 'NAME') {
                            self.searchCritriaOption.push({ 'filterType': 'name', 'name': response.data[index] })
                        } else if (response.data[index] == 'ADDRESS') {
                            self.searchCritriaOption.push({ 'filterType': 'address', 'name': response.data[index] })
                        } else if (response.data[index] == 'MOBILE_NO') {
                            self.searchCritriaOption.push({ 'filterType': 'mobileNo', 'name': response.data[index] })
                        } else if (response.data[index] == 'EMAIL_ID') {
                            self.searchCritriaOption.push({ 'filterType': 'emailId', 'name': response.data[index] })
                        } else if (response.data[index] == 'MEMBERSHIP_ID') {
                            self.searchCritriaOption.push({ 'filterType': 'membershipId', 'name': response.data[index] })
                        }

                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
    },
    computed: {

    },
    created() {
        this.getSearchFiltersList();
        this.getStoreName();
        this.getUserRole();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.imageIcons {
    margin-top: -25px;
    position: relative;
    z-index: 1;

}

.bloodDrop {
    color: red;
    width: 16px;
    height: 16px;
    stroke-width: 5;
}

.bloodGroup {
    color: #000;
    font-weight: bolder;
}

.isActive {
    color: green;
    width: 18px;
    height: 18px;
    stroke-width: 5;
}

.isInActive {
    color: gray;
    width: 18px;
    height: 18px;
    stroke-width: 5;
}

.empDetail {
    font-size: 12px;
}

.inline {
    padding: 1px;
    display: inline;
}
</style>
